<template>
  <div class="semi-circle-container">
    <div class="semi-circle-up">
      <vue-c3 ref="semi-circle-up" :handler="handlerDown" style="height: 100%"></vue-c3>
    </div>
    <div class="semi-circle-down">
      <vue-c3 ref="semi-circle-down" :handler="handlerUp" style="height: 100%"></vue-c3>
    </div>
    <div class="overpacing-text" :class="[pacingBeyondThreshold ? 'red' : '']">
      {{ Math.abs(Math.round(initialPercentDiff)) }}%
      {{ pacingText }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import defaultTheme from '@/plugins/Theme/defaultTheme';
import formatNumber from '~/util/numeral';

export default {
  components: {
    VueC3,
  },
  props: {
    initialUpValue: {
      type: Number,
      required: false,
      default: 0,
    },
    initialDownValue: {
      type: Number,
      required: false,
      default: 0,
    },
    initialPercentDiff: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      handlerDown: new Vue(),
      handlerUp: new Vue(),
    };
  },
  computed: {
    pacingText() {
      let text = '';
      if (Math.round(this.initialPercentDiff) < 0) {
        text = 'Underpacing';
      } else if (Math.round(this.initialPercentDiff) === 0) {
        text = 'Pacing';
      } else {
        text = 'Overpacing';
      }
      return text;
    },
    pacingBeyondThreshold() {
      return Math.round(this.initialPercentDiff) < -15 || Math.round(this.initialPercentDiff) > 15;
    },
    optionsDown() {
      return {
        data: {
          columns: [['Actual Pacing', 0]],

          type: 'gauge',
        },
        legend: { show: false },
        transition: {
          duration: 1800,
        },
        gauge: {
          width: 5,
          label: {
            format(value) {
              return value;
            },
            show: true, // to turn off the min/max labels.
          },
          min: 0, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
        },
        size: {
          height: 128,
        },
        tooltip: {
          format: {
            value(value) {
              return formatNumber(value, 'percentage');
            },
          },
        },
        color: {
          pattern: ['#f5493a', defaultTheme.primaryColor], // the two color levels for the percentage values.
          threshold: {
            values: [15, 100],
          },
        },
      };
    },
    optionsUp() {
      return {
        data: {
          columns: [['Expected Pacing', 0]],
          type: 'gauge',
        },
        legend: { show: false },
        gauge: {
          width: 4,
          label: {
            format(value) {
              return value;
            },
            show: true, // to turn off the min/max labels.
          },
          min: 0, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
        },
        size: {
          height: 133,
        },
        tooltip: {
          format: {
            value(value) {
              return formatNumber(value, 'percentage');
            },
          },
        },

        color: {
          pattern: [
            'rgba(212,212,222,0.7)',
            'rgba(212,212,222,0.7)',
            'rgba(212,212,222,0.7)',
            'rgba(212,212,222,0.7)',
          ], // the three color levels for the percentage values.
          threshold: {
            values: [15, 100],
          },
        },
      };
    },
  },
  watch: {
    initialUpValue(n) {
      this.handlerUp.$emit('dispatch', (chart) => {
        chart.load({
          columns: [['Expected Pacing', Math.round(n)]],
        });
      });

      this.animateCount(this.$refs['semi-circle-down'].$el.querySelector('.c3-gauge-value'));
    },
    initialDownValue(n) {
      this.handlerDown.$emit('dispatch', (chart) => {
        chart.load({
          columns: [['Actual Pacing', Math.round(n)]],
        });
      });

      this.animateCount(this.$refs['semi-circle-up'].$el.querySelector('.c3-gauge-value'));
    },
  },
  mounted() {
    const vm = this;

    vm.render();
  },
  beforeDestroy() {
    const vm = this;

    vm.nuke();
  },
  methods: {
    render() {
      const vm = this;

      vm.nuke();

      vm.handlerDown.$emit('init', vm.optionsDown);
      vm.$nextTick(() => {
        vm.handlerDown.$emit('dispatch', (chart) => {
          chart.load({
            columns: [['Actual Pacing', Math.round(vm.initialDownValue)]],
          });
        });
        const animateEl = vm.$refs['semi-circle-up'];
        if (animateEl) {
          const gaugeValEl = animateEl.$el.querySelector('.c3-gauge-value');
          if (gaugeValEl) {
            vm.animateCount(gaugeValEl);
          }
        }
      });
      vm.handlerUp.$emit('init', vm.optionsUp);
      vm.$nextTick(() => {
        vm.handlerUp.$emit('dispatch', (chart) => {
          chart.load({
            columns: [['Expected Pacing', Math.round(vm.initialUpValue)]],
          });
        });
      });
    },
    animateCount(el) {
      // How long you want the animation to take, in ms
      const animationDuration = 1500;
      // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
      const frameDuration = 1000 / 60;
      // Use that to calculate how many frames we need to complete the animation
      const totalFrames = Math.round(animationDuration / frameDuration);
      // An ease-out function that slows the count as it progresses
      const easeOutQuad = (t) => t * (2 - t);

      let percentEl = el.parentElement.querySelector('text[data-name="percent"]');
      if (!percentEl) {
        percentEl = el.cloneNode(true);
      }
      el.parentNode.append(percentEl);
      percentEl.style.textAnchor = '';
      percentEl.setAttribute('data-name', 'percent');
      percentEl.innerHTML = '%';
      let frame = 0;
      const countTo = parseInt(el.innerHTML, 10);
      // Start the animation running 60 times per second
      const counter = setInterval(() => {
        frame++;
        // Calculate our progress as a value between 0 and 1
        // Pass that value to our easing function to get our
        // progress on a curve
        const progress = easeOutQuad(frame / totalFrames);
        // Use the progress value to calculate the current count
        const currentCount = Math.round(countTo * progress);

        // If the current count has changed, update the element
        if (parseInt(el.innerHTML, 10) !== currentCount) {
          el.innerHTML = currentCount;
          percentEl.setAttribute(
            'style',
            `
              opacity: 0.4;
              pointer-events: none;
              font-size: 22px !important;
              font-weight: 100;
              position: relative;
              transform: translate(${el.getBoundingClientRect().width / 2}px, -2%);
              color: #555f6f;
            `
          );
        }

        // If we’ve reached our last frame, stop the animation
        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    },
    nuke() {
      const vm = this;

      vm.handlerDown.$emit('destroy');
      vm.handlerUp.$emit('destroy');
    },
  },
};
</script>

<style lang="scss" scoped>
.semi-circle-container {
  position: relative;
  min-height: 140px;

  .overpacing-text {
    position: absolute;
    bottom: 12px;
    left: 50%;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
    color: var(--primarygreen);
    text-align: center;
    transform: translateX(-50%);
    text-transform: capitalize;
    &.red {
      color: var(--primaryred);
    }
  }
  .semi-circle-up,
  .semi-circle-down {
    position: absolute;
    pointer-events: none;
  }

  .semi-circle-up {
    top: 0px;
    z-index: 3;
  }

  .semi-circle-down {
    top: -5px;
    z-index: 1;
  }
}

::v-deep .semi-circle-up .c3-chart-arcs-gauge-unit,
::v-deep .semi-circle-down .c3-chart-arcs-gauge-unit {
  display: none;
}

::v-deep .semi-circle-up .c3-chart-arcs-background path,
::v-deep .semi-circle-down .c3-chart-arcs-background path {
  stroke: transparent;
}

::v-deep .semi-circle-down .c3-chart-arcs .c3-chart-arcs-gauge-min,
::v-deep .semi-circle-down .c3-chart-arcs .c3-chart-arcs-gauge-max,
::v-deep .semi-circle-down .c3-chart-arc .c3-gauge-value {
  display: none;
}

::v-deep .semi-circle-down .c3-chart-arcs .c3-chart-arcs-background {
  border: none;
  fill: transparent !important;
}

::v-deep .semi-circle-up .c3 line .c3 path {
  fill: var(--primarygreen) !important;
}

::v-deep .semi-circle-up .c3-gauge-value {
  display: inline-block;
  font-size: 41px !important;
  font-weight: 100;
  fill: #ccc;
  transform: translateX(-1%);
}

.perc-wrap {
  position: absolute;
  left: 56%;
  width: 30px;
  height: 42px;
  margin: 67px 0px 0px 32px;
  font-size: 22px;
  font-weight: 100;
  color: #555f6f;
  opacity: 0.4;
}

.perc-wrap .c3-chart-arcs-gauge-unit {
  font-size: 16px;
  color: #666;
}

.z9 {
  z-index: 9 !important;
}
::v-deep .c3-arc-Actual-Pacing {
  pointer-events: all;
  fill: var(--primarycolor) !important;
}
::v-deep .c3-arc-Expected-Pacing {
  pointer-events: all;
}

.legend span {
  display: block;
  height: 13px;
  padding-left: 6px;
  margin: 5px 0px;
  overflow: hidden;
  font-family: sans-serif;
  font-size: 11px;
  color: rgba(130, 130, 140, 0.8);
  text-align: left;
  cursor: pointer;
  border-left-style: dotted;
  border-left-width: 11px;
}

.legend {
  position: absolute;
  top: 80px;
  right: 20px;
  width: 240px;
  height: 270px;
  overflow-y: scroll;
  text-align: left;
}

::v-deep .c3-chart-arc path {
  stroke: rgba(0, 0, 0, 0.1);
}

.semi-circle-up ::v-deep .c3-tooltip-container {
  top: 83% !important;
}
.semi-circle-down ::v-deep .c3-tooltip-container {
  top: 83.5% !important;
}
::v-deep .c3-tooltip-container {
  left: 50% !important;
  width: 168px;
  margin-left: -85px;
  border-radius: 4px;
  text-transform: capitalize;
}

::v-deep .c3-tooltip {
  width: 100%;
  // background-color: transparent;
  box-shadow: none;
  // opacity: 0.9;
}
::v-deep .c3-tooltip td {
  padding: 4px 6px;
  font-size: 11px;
  color: #fff;
  background-color: #222;
  border-left: 0px dotted #999;
}
::v-deep .c3-tooltip td.value {
  font-size: 12px;
  font-weight: 300;
  border-left: 1px dotted rgba(130, 130, 140, 0.2);

  /*display: none;*/
}
::v-deep .c3-tooltip tr {
  border: 0px solid #ccc;
}

.ctip-perc {
  opacity: 0.6;
}

::v-deep .c3-chart-arcs .c3-chart-arcs-gauge-unit {
  display: inline-block;
  font-size: 16px;
  fill: rgba(170, 170, 170, 0.9);
}

::v-deep .c3-chart-arcs .c3-chart-arcs-background {
  border: none;
  fill: #a2a2a2 !important;
}
</style>
